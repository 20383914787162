import React from "react"
import ContentLoaderSingleCard from "./ContentLoaderSingleCard"

const ContentLoaderJobGrid = ({ count, grid=4, height=180 }) => {
    const dataArr = Array.from(Array(count).keys())
    return (<ul className="row justify-content-center" style={{ padding: 0 }}>
        {dataArr.map((i)=> (
            <li
                key={i}
                className={`col-${grid} mb-5`}
                style={{
                    height:height,
                    listStyle: 'none',
                }}
            >
                <div className="shadow card mb-3" style={{height:height}}>
                    <ContentLoaderSingleCard height={height} width={330} />
                </div>
            </li>
        ))}

    </ul>)
}

export default ContentLoaderJobGrid